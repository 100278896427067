<template>
  <div class="wrapper  flex align-start justify-between">
    <div class="flex-sub">
      <el-form :model="entryForm"
               label-width="140px"
               :rules="rules" ref="entryForm" class="add-form">
        <el-form-item label="姓名" prop="xingming">
          <el-input v-model="entryForm.xingming" placeholder="请填写姓名" disabled></el-input>
        </el-form-item>
        <el-form-item label="所属部门" prop="lishubumen">
          <el-input v-model="entryForm.lishubumen" placeholder="请填写所属部门"></el-input>
        </el-form-item>
        <el-form-item label="岗位名称" prop="zhiweimingcheng">
          <el-input v-model="entryForm.zhiweimingcheng" placeholder="请填写岗位名称"></el-input>
        </el-form-item>

        <el-form-item label="上级领导" prop="zhijieshangji">
          <el-select v-model=" entryForm.zhijieshangji" placeholder="请选择上级领导"
                     @change="setUpInfo" class="w-all">
            <el-option
                v-for="item in superior"
                :key="item.id"
                :label="item.xingming"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上级领导微信" prop="LeaderWechat">
          <el-input v-model="entryForm.LeaderWechat" placeholder="请填写上级领导微信"></el-input>
        </el-form-item>
        <el-form-item label="上级领导手机号" prop="LeaderMobile">
          <el-input v-model="entryForm.LeaderMobile" placeholder="请填写上级领导手机号"></el-input>
        </el-form-item>
        <el-form-item label="入职日期" prop="ruzhiriqi">
          <el-date-picker align="center"
                          class="w-all"
                          v-model="entryForm.ruzhiriqi"
                          type="date"
                          placeholder="请选择入职日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="工作地点" prop="ConsultantWorkLocation">
          <el-input v-model="entryForm.ConsultantWorkLocation" placeholder="请填写工作地点"></el-input>
        </el-form-item>
        <el-form-item label="回复日期" prop="ConsultantReplyDate">
          <el-date-picker align="center"
                          class="w-all"
                          v-model="entryForm.ConsultantReplyDate"
                          type="date"
                          placeholder="请选择回复日期">
          </el-date-picker>
        </el-form-item>


        <el-form-item label-width="0" class="flex justify-around">
          <el-button class="submit-btn" type="primary" @click="submitForm('entryForm')">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="flex-sub  preview padding-lr">
      <div class="preview-info">
        <div class="preview-tips" v-if="!sys_offer_file">保存后可预览</div>
        <iframe v-else ref="iframe" frameborder="0" style="width: 100%;height: calc(100vh - 335px);"></iframe>
      </div>

      <div class="flex align-center justify-around margin-top">
        <el-button class="submit-btn margin-right" type="primary" @click="downOffer">下载入职通知
        </el-button>

        <el-upload
            ref="upBtn"
            class="upload-demo"
            :action="actions"
            :data="{token}"
            :on-success="handleSuccess"
            :limit="1"
            :show-file-list="false"
        >
          <el-button class="submit-btn" type="warning">上传入职通知反馈</el-button>

        </el-upload>
      </div>
      <div class="flex align-center justify-center margin-top" v-if="upload_file">
        <div>反馈附件：</div>
        <el-link :href="upload_file" target="_blank" type="primary">查看入职通知反馈附件
        </el-link>
      </div>

    </div>

  </div>
</template>

<script>

import rules from "@/utils/rules";
import {
  downLoadOfferWord, entryNoticePartTime,
  entryNoticeShow,
  replyNoticeFile,
  shangJiList
} from "@/api/entry";
import {getPageName, zhDate} from "@/utils";
import {QAHOST, upFile} from "@/config";
import {getOfferHtml} from "@/api/common";

export default {
  name: "OrgList",
  data() {
    return {
      id: '',
      superior: [],
      entryForm: {
        xingming: '',
        lishubumen: '',
        zhiweimingcheng: '',
        zhijieshangji: '',
        LeaderWechat: '',
        LeaderMobile: '',
        ruzhiriqi: '',
        ConsultantWorkLocation: '',
        ConsultantReplyDate: '',
      },
      rules: {
        mingcheng: rules.mustInput,
        lishubumen: rules.mustInput,
        zhiweimingcheng: rules.mustInput,
        zhijieshangji: rules.mustSelect,
        LeaderWechat: rules.mustInput,
        LeaderMobile: rules.mustInput,
        ruzhiriqi: rules.mustDate,
        ConsultantWorkLocation: rules.mustInput,
        ConsultantReplyDate: rules.mustDate,
      },
      isSubmit: true,
      sys_offer_file: '',
      upload_file: '',
      iframe: '',

    }
  },
  computed: {
    token() {
      return this.$store.state.token
    },
    actions() {
      return upFile
    }
  },
  created() {
    this.$emit("setIndex", '/admin/expertList', "兼职员工入职通知", true);
    this.shangJiList()
    if (Number(this.$route.params.id)) {
      this.id = Number(this.$route.params.id)
      this.entryNoticeShow()
    }
  },
  components: {},
  methods: {
    //  获取上级
    async shangJiList() {
      const res = await shangJiList()
      this.superior = res.data
    },
    setUpInfo(val){
      let _index = this.superior.findIndex(item=>item.id === val)
      this.entryForm.shangjiweixin = this.superior[_index].weixin
      this.entryForm.shangjishouji = this.superior[_index].dianhua
    },
    async entryNoticeShow() {
      const res = await entryNoticeShow(this.id, 3)
      this.sys_offer_file = res.data.sys_offer_file
      this.upload_file = res.data.upload_file ? QAHOST + '/' + res.data.upload_file : ''
      this.entryForm = {
        xingming: res.data.xingming,
        lishubumen: res.data.lishubumen,
        zhiweimingcheng: res.data.zhiweimingcheng,
        zhijieshangji: Number(res.data.zhijieshangji),
        LeaderWechat: res.data.LeaderWechat,
        LeaderMobile: res.data.LeaderMobile,
        ruzhiriqi:res.data.ruzhiriqi? new Date(res.data.ruzhiriqi):'',
        ConsultantWorkLocation: res.data.ConsultantWorkLocation,
        ConsultantReplyDate: res.data.ConsultantReplyDate?new Date(res.data.ConsultantReplyDate):''


      }
      if (res.data.sys_offer_file) {
        const data = await getOfferHtml(getPageName(res.data.sys_offer_file))
        this.iframe = QAHOST + '/' + data.data + '?v=' + new Date().getTime()
        this.$refs.iframe.contentWindow.location.replace(this.iframe)
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.sureAdd()
        }
      });
    },
    async sureAdd() {
      if (!this.isSubmit) return
      this.isSubmit = false
      let _state = await entryNoticePartTime(
          this.id,
          this.entryForm.xingming,
          this.entryForm.lishubumen,
          this.entryForm.zhiweimingcheng,
          this.entryForm.zhijieshangji,
          this.entryForm.LeaderWechat,
          this.entryForm.LeaderMobile,
          zhDate(new Date(this.entryForm.ruzhiriqi)),
          this.entryForm.ConsultantWorkLocation,
          zhDate(new Date(this.entryForm.ConsultantReplyDate))
      )

      this.isSubmit = true
      if (_state.status === 200) {
        this.$message.success(_state.info)
        if (!this.id) {
          this.id = Number(_state.data)
          await this.$router.replace(`/admin/partTime/entryInfo/${_state.data}`)
        }
        await this.entryNoticeShow()
      } else {
        this.$message.error(_state.info)
      }
    },
    //  下载附件
    async downOffer() {
      if (!this.iframe) return this.$message.error('请编辑保存后生成')
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let _data = await downLoadOfferWord(this.id, this.sys_offer_file)
      loading.close();
      let blob = _data.data
      var downloadElement = document.createElement('a');
      var href = window.URL.createObjectURL(blob); //创建下载的链接
      downloadElement.style.display = 'none';
      downloadElement.href = href;
      downloadElement.setAttribute('download', this.entryForm.xingming + '入职通知.docx');
      document.body.appendChild(downloadElement);
      downloadElement.click(); //点击下载
      document.body.removeChild(downloadElement); //下载完成移除元素
      window.URL.revokeObjectURL(href); //释放掉blob对象
    },
    handleSuccess(file) {
      if (file.status === 200) {
        this.upFile(file.data)
      } else {
        this.$message.error(file.msg)
      }
      this.$refs['upBtn'].clearFiles()
    },
    async upFile(url) {
      await replyNoticeFile(this.id, 3, url)
      this.$message.success('上传成功')
      await this.entryNoticeShow()
    },
  },
  filters: {},
  watch: {},
}
</script>

<style scoped lang="scss">
.preview {
  height: 100%;

  .preview-title {
    text-align: center;
    line-height: 60px;
    font-size: 22px;
  }

  .preview-info {
    width: 100%;
    height: calc(100vh - 330px);
    overflow-y: auto;
    padding: 0 30px;
    border: 1px solid #DCDFE6;
    border-radius: 10px;
  }
}
</style>
